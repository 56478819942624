body {
  background: url("/images/party-2-bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;

  &:before {
    background: white;
    content: "";
    inset: 0;
    opacity: 0.2;
    position: absolute;
    z-index: 0;
  }
}

.App {
  align-items: center;
  color: #333;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 40px);
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.header {
  border: 10px solid #333;
  border-width: 10px 0;
  margin: 0;
  padding: 20px 0;

  p {
    font-size: 1.4rem;
    margin: 0;

    @media (min-width: 800px) {
      font-size: 2rem;
    }
  }
}

.logo {
  margin-bottom: 10px;
  max-width: 200px;
  opacity: 0.85;
}

.info {
  font-size: 1.2rem;
  padding: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.gurner {
  display: block;
  margin: 0 auto 10px;
  max-width: 100px;
}

.smallButton {
  background: none;
  border: 0;
  text-decoration: underline;

  &:hover {
    background: none;
    border: 0;
  }
}

.approvalImage {
  margin-bottom: 10px;
}
