body {
  margin: 0;
  font-family: "Lora", "Georgia", serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  max-width: 66ch;
}

h1 {
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;

  @media (min-width: 800px) {
    font-size: 3rem;
  }
}

p {
  margin: 0 0 20px;
}

a {
  color: #333;

  &:hover,
  &:active,
  &:focus {
    color: black;
  }

  &:visited {
    color: black;
  }
}

button,
.button {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  cursor: pointer;
  font-family: "Lora", "Georgia", serif;
  font-size: 1.2rem;
  padding: 8px 16px;
  text-decoration: none;
  transition: all 300ms;

  &:hover {
    background: #eee;
    border: 2px solid #000;
    text-decoration: none;
    transition: all 300ms;
  }
}
